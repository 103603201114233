import styled from 'styled-components';
import { Container, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export const PromoBannerContainer = styled.div`
  background: url(${props => props.background}) no-repeat top center;
  margin: -20px 0 0 0;
`;

export const PromoBannerInnerContainer = styled(Container)`
  color: #fff;
  margin: 0 auto;
  align-content: center;
`;

export const AnchorTag = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 710px;
  margin: 0 auto;
`;

export const AnchorTagInternal = styled(Link)`
  text-decoration: none;
`;

export const Column = styled(Col)`
  text-align: center;
  display: block;
  @media (min-width: 992px) {
    padding: 20px 0 20px 0;
  }
  @media (max-width: 992px) {
    padding: 55px 35px 60px 35px;
  }

  img {
    max-width: 100%;
  }
`;

export const ImageStyle = styled.img`
  background-position-x: center;
  background-position-y: top;
  background-size: initial;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(3, 73, 125);
`